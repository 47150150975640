import { CoreActionEnum } from "../constants";

export function showHideAlert(data) {
  return (dispatch) => {
    dispatch({
      type: CoreActionEnum.SHOW_HIDE_ALERT,
      payload: data,
    });
  };
}

export function showHidePlatformUpdate(data) {
  return (dispatch) => {
    dispatch({
      type: CoreActionEnum.SHOW_HIDE_PLATFORM_UPDATE,
      payload: data,
    });
  };
}

export const onStartUpdating = () => (dispatch) => {
  dispatch({
    type: CoreActionEnum.SET_ISUPDATING_MODE,
  });
};

export const onCancelUpdating = () => (dispatch) => {
  dispatch({
    type: CoreActionEnum.CANCEL_UPDATING_MODE,
  });
};

export const setPlatformUpdateText = (text: string) => ({
  type: CoreActionEnum.SET_PLATFORM_UPDATE_TEXT,
  payload: text,
});

export const setPlatformUpdateLink = (link: string) => ({
  type: CoreActionEnum.SET_PLATFORM_UPDATE_LINK,
  payload: link,
});

export const setPlatformUpdateID = (id: number) => ({
  type: CoreActionEnum.SET_PLATFORM_UPDATE_ID,
  payload: id,
});
