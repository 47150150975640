import * as types from '../constants/clients';
import { OrNull, UserType } from '../types';
import { Client } from '../utils';

type ClientUser = UserType & {};

type CsmItem = {
  id: number,
  name: string
};

type ClientsType = {
  clients: Client[],
  allclients: [],
  clientUsers: ClientUser[],
  url: OrNull<string>,
  current_page: OrNull<number>,
  last_page: OrNull<number>,
  total: OrNull<number>,
  per_page: number,
  type: OrNull<string>,
  client: null,
  clientsList: [],
  csmList: CsmItem[],
};

const initialState: ClientsType = {
  clients: [],
  allclients: [],
  clientUsers: [],
  url: null,
  current_page: null,
  last_page: null,
  total: null,
  per_page: 8,
  type: null,
  client: null,
  clientsList: [],
  csmList: [],
};

export default function clients(state = initialState, action): ClientsType {
  switch (action.type) {
    case types.GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        allclients: action.payload.data,
      };
    case types.GET_CLIENT_USERS_SUCCESS:
      return {
        ...state,
        clientUsers: action.payload,
      };
    case types.GET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case types.GET_SIGNUPS_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
        type: 'signups',
      };
    case types.GET_ACTIVE_CLIENTS_SUCCESS:
    case types.GET_INACTIVE_CLIENTS_SUCCESS:
    case types.SEARCH_CLIENT_SUCCESS:
    case types.SET_PAGE_CLIENTS_PAGE_SUCCESS:
      return {
        ...state,
        clients: action.payload.data,
        current_page: action.payload.current_page,
        last_page: action.payload.last_page,
        total: action.payload.total,
        type: null,
      };
    case types.GET_SIGNUPS_CLIENTS_URL:
    case types.GET_ACTIVE_CLIENTS_URL:
    case types.GET_INACTIVE_CLIENTS_URL:
    case types.GET_CLIENTS_URL:
      return {
        ...state,
        url: action.payload,
      };
    case types.ACCEPT_CLIENT_SUCCESS:
    case types.DECLINE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(item => item.id !== action.payload),
      };
    case types.GET_CLIENTS_LIST:
      return {
        ...state,
        clientsList: action.payload,
      };
    case types.GET_CSM_LIST:
      return {
        ...state,
        csmList: action.payload,
      };
    default:
      return state;
  }
}
