export enum CoreActionEnum {
  SHOW_HIDE_ALERT = 'SHOW_HIDE_ALERT',

  SHOW_HIDE_PLATFORM_UPDATE = 'SHOW_HIDE_PLATFORM_UPDATE',
  SET_PLATFORM_UPDATE_TEXT = 'SET_PLATFORM_UPDATE_TEXT',
  SET_PLATFORM_UPDATE_LINK = 'SET_PLATFORM_UPDATE_LINK',
  SET_PLATFORM_UPDATE_ID = 'SET_PLATFORM_UPDATE_ID',

  SET_ISUPDATING_MODE = 'SET_ISUPDATING_MODE',
  CANCEL_UPDATING_MODE = 'CANCEL_UPDATING_MODE',
}
