import * as types from '../constants';
import { ProfessionalsAction } from '../constants';

const initialState = {
  clientProfile: {},
  companyProfile: {},
  writerProfile: {},
  designerProfile: {},
  adminProfile: {},
  professionalProfile: {},
  errorMessage: '',
  successfullyChangePassword: false,
  changePasswordModal: false,
  experience: [],
  highlights: [],
  portfolios: [],
  billingInfo: {},
};

export default function userProfile(state = initialState, action) {
  switch (action.type) {
    case types.GET_CLIENT_PROFILE_SUCCESS:
      return {
        ...state,
        clientProfile: action.payload,
      };
    case types.GET_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        companyProfile: action.payload,
      };
    case types.ADD_CLIENT_FILE_SUCCESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          files: [...state.clientProfile.files, action.payload],
        },
      };
    case types.REMOVE_CLIENT_FILE_SUCCESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          files: [
            ...state.clientProfile.files.filter(item => item.id !== action.payload),
          ],
        },
      };
    case types.ADD_COMPANY_FILE_SUCCESS:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          files: [...state.companyProfile.files, action.payload],
        },
      };
    case types.REMOVE_COMPANY_FILE_SUCCESS:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          files: [
            ...state.companyProfile.files.filter(item => item.id !== action.payload),
          ],
        },
      };
    case types.UPDATE_CLIENT_PROFILE:
      return {
        ...state,
        clientProfile: action.payload,
      };
    case types.UPDATE_COMPANY_PROFILE:
      return {
        ...state,
        companyProfile: action.payload,
      };
    case types.ADD_CLIENT_USER_SUCCESS:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          users: [...state.companyProfile.users, action.payload],
        },
      };
    case types.REMOVE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          users: [
            ...state.companyProfile.users.filter(item => item.id !== action.payload.id),
          ],
        },
      };
    case types.UPDATE_CLIENT_USER_SUCCESS:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          users: [
            ...state.companyProfile.users.map(item => {
              if (item.id === action.payload.id) {
                return action.payload;
              }
              return item;
            }),
          ],
        },
      };
    case types.UPDATE_CLIENT_MAIN_USER:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          main_user_id: action.payload,
        },
      };
    case types.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clientProfile: {},
      };
    case types.REMOVE_WRITER_FROM_TEAM_SUCCESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          writers: [
            ...state.clientProfile.writers.filter(item => item.id !== action.payload),
          ],
        },
      };
    case types.REMOVE_DESIGNER_FROM_TEAM_SUCCESS:
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          designers: [
            ...state.clientProfile.designers.filter(item => item.id !== action.payload),
          ],
        },
      };
    case types.UPDATE_ADMIN_CLIENT_SUCCESS: {
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          admins: action.payload,
        },
      };
    }
    case types.SET_CSM_LEADING: {
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          leading_id: action.payload,
        },
      };
    }
    case types.ADD_WATCHER_TO_CLIENT: {
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          watchers: [
            ...state.companyProfile.watchers,
            action.payload,
          ],
        },
      };
    }
    case types.REMOVE_ADMIN_FROM_CLIENT: {
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          admins: state.companyProfile.admins.filter(item => item.user.id !== action.payload),
        },
      };
    }
    case types.REMOVE_WATCHER_FROM_CLIENT: {
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          watchers: state.companyProfile.watchers.filter(item => item.id !== action.payload),
        },
      };
    }
    case types.GET_WRITER_PROFILE_SUCCESS:
      return {
        ...state,
        writerProfile: action.payload,
      };
    case types.GET_DESIGNER_PROFILE_SUCCESS: {
      return {
        ...state,
        designerProfile: action.payload,
      };
    }
    case types.GET_PROFESSIONAL_PROFILE_SUCCESS:
    case types.UPDATE_PROFESSIONAL_PROFILE: {
      return {
        ...state,
        professionalProfile: action.payload,
      };
    }
    case types.ADD_FILE_TO_PORTFOLIO_SUCCESS:
      return {
        ...state,
        writerProfile: {
          ...state.writerProfile,
          portfolios: [
            ...state.writerProfile.portfolios, action.payload],
        },
      };
    case types.REMOVE_FILE_FROM_PORTFOLIO_SUCCESS:
      return {
        ...state,
        writerProfile: {
          ...state.writerProfile,
          portfolios: [
            ...state.writerProfile.portfolios.filter(item => item.id !== action.payload),
          ],
        },
      };
    case types.ADD_FILE_TO_DESIGNER_PORTFOLIO_SUCCESS:
      return {
        ...state,
        designerProfile: {
          ...state.designerProfile,
          portfolios: [
            ...state.designerProfile.portfolios, action.payload],
        },
      };
    case types.REMOVE_FILE_FROM_DESIGNER_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        designerProfile: {
          ...state.designerProfile,
          portfolios: [
            ...state.designerProfile.portfolios.filter(item => item.id !== action.payload),
          ],
        },
      };
    }
    case types.UPDATE_WRITER_PROFILE:
      return {
        ...state,
        writerProfile: action.payload,
      };
    case types.DELETE_WRITER_SUCCESS:
      return {
        ...state,
        writerProfile: {},
      };
    case types.UPDATE_DESIGNER_PROFILE:
      return {
        ...state,
        designerProfile: action.payload,
      };
    case types.DELETE_DESIGNER_SUCCESS:
      return {
        ...state,
        designerProfile: {},
      };
    case types.GET_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        adminProfile: action.payload,
      };
    case types.UPDATE_ADMIN_PROFILE:
      return {
        ...state,
        adminProfile: action.payload,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        successfullyChangePassword: true,
      };
    case types.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        successfullyChangePassword: false,
      };
    case types.OPEN_MODAL:
      return {
        ...state,
        changePasswordModal: true,
      };
    case types.CLOSE_MODAL:
      return {
        ...state,
        changePasswordModal: false,
        successfullyChangePassword: false,
      };
    case types.CLEAR_PROFILE_DATA:
      return {
        ...initialState,
      };
    case types.UPDATE_AVAILABILITY: {
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          user: {
            ...state[action.field].user,
            holidays: action.payload,
          },
        },
      };
    }
    case types.UPDATE_CLIENT_AVAILABILITY: {
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          holidays: action.payload,
        },
      };
    }
    case types.GET_COMPANY_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          departments: action.payload,
        },
      };
    }
    case types.ADD_COMPANY_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          departments: [...state.clientProfile.departments, action.payload],
        },
      };
    }
    case types.DELETE_COMPANY_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          departments: [...state.clientProfile.departments.filter(item => item.id !== action.payload)],
        },
        companyProfile: {
          ...state.companyProfile,
          users: [
            ...state.companyProfile.users.map(user => ({
              ...user,
              department_user: user.department_user.filter(dep => dep.department_id !== action.payload),
            })),
          ],

        },
      };
    }
    case types.UPDATE_COMPANY_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        clientProfile: {
          ...state.clientProfile,
          departments: [
            ...state.clientProfile.departments.map(item => (item.id === action.payload.id ? action.payload : item)),
          ],
        },
      };
    }
    case types.UPDATE_WRITER_STATUS: {
      return {
        ...state,
        writerProfile: {
          ...state.writerProfile,
          user: {
            ...state.writerProfile.user,
            state: action.payload,
          },
        },
      };
    }
    case types.DEACTIVATE_WRITER: {
      return {
        ...state,
        writerProfile: {
          ...state.writerProfile,
          user: {
            ...state.writerProfile.user,
            state: action.payload.state,
            deactivation: action.payload.deactivation,
          },
        },
      };
    }
    case ProfessionalsAction.DEACTIVATE_PROFESSIONAL:
      return {
        ...state,
        professionalProfile: {
          ...state.professionalProfile,
          user: {
            ...state.professionalProfile.user,
            state: action.payload.state,
            deactivation: action.payload.deactivation,
          },
        },
      };
    case types.UPDATE_DESIGNER_STATUS: {
      return {
        ...state,
        designerProfile: {
          ...state.designerProfile,
          user: {
            ...state.designerProfile.user,
            state: action.payload,
          },
        },
      };
    }
    case types.DEACTIVATE_DESIGNER: {
      return {
        ...state,
        designerProfile: {
          ...state.designerProfile,
          user: {
            ...state.designerProfile.user,
            state: action.payload.state,
            deactivation: action.payload.deactivation,
          },
        },
      };
    }
    case types.GET_FREELANCER_EXPERIENCES_SUCCESS: {
      return {
        ...state,
        experience: action.payload,
      };
    }
    case types.ADD_FREELANCER_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        experience: [...state.experience, action.payload],
      };
    }
    case types.DELETE_FREELANCER_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        experience: [...state.experience.filter(item => item.id !== action.payload)],
      };
    }
    case types.UPDATE_FREELANCER_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        experience: [
          ...state.experience.map(item => (item.id === action.payload.id ? action.payload : item)),
        ],
      };
    }
    case types.GET_FREELANCER_HIGHLIGHTS_SUCCESS: {
      return {
        ...state,
        highlights: action.payload,
      };
    }
    case types.GET_FREELANCER_PORTFOLIO_WORK_SUCCESS: {
      return {
        ...state,
        portfolios: action.payload,
      };
    }
    case types.ADD_FREELANCER_PORTFOLIO_WORK_SUCCESS: {
      return {
        ...state,
        portfolios: [...state.portfolios, action.payload],
      };
    }
    case types.UPDATE_FREELANCER_PORTFOLIO_WORK_SUCCESS: {
      return {
        ...state,
        highlights: [
          ...state.highlights.map(item => (item.id === action.payload.id ? action.payload : item)),
        ],
        portfolios: [
          ...state.portfolios.map(item => (item.id === action.payload.id ? action.payload : item)),
        ],
      };
    }
    case types.DELETE_FREELANCER_PORTFOLIO_WORK_SUCCESS: {
      return {
        ...state,
        highlights: [...state.highlights.filter(item => item.id !== action.payload)],
        portfolios: [...state.portfolios.filter(item => item.id !== action.payload)],
      };
    }
    case types.GET_FREELANCER_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        billingInfo: action.payload,
      };
    }
    case types.UPDATE_FREELANCER_BILLING_INFO_SUCCESS: {
      return {
        ...state,
        billingInfo: action.payload,
      };
    }
    case types.UPDATE_WRITER_AVATAR:
      return {
        ...state,
        writerProfile: {
          ...state.writerProfile,
          user: {
            ...state.writerProfile.user,
            avatar: action.payload,
          },
        },
      };
    case types.UPDATE_DESIGNER_AVATAR:
      return {
        ...state,
        designerProfile: {
          ...state.designerProfile,
          user: {
            ...state.designerProfile.user,
            avatar: action.payload,
          },
        },
      };
    case types.UPDATE_PROFESSIONAL_AVATAR:
      return {
        ...state,
        professionalProfile: {
          ...state.professionalProfile,
          user: {
            ...state.professionalProfile.user,
            avatar: action.payload,
          },
        },
      };
    case types.UPDATE_CLIENT_STATUS_SUCCESS:
      return {
        ...state,
        companyProfile: {
          ...state.companyProfile,
          state: action.payload,
        },
      };
    case types.UPDATE_WRITER_IS_TIPALTI_ONBOARDING_DISABLED:
      return {
        ...state,
        writerProfile: {
          ...state.writerProfile,
          user: {
            ...state.writerProfile.user,
            is_tipalti_onboarding_disabled: action.payload,
          },
        },
      };
    case types.UPDATE_DESIGNER_IS_TIPALTI_ONBOARDING_DISABLED:
      return {
        ...state,
        designerProfile: {
          ...state.designerProfile,
          user: {
            ...state.designerProfile.user,
            is_tipalti_onboarding_disabled: action.payload,
          },
        },
      };
    default:
      return state;
  }
}
