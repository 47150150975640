import { FinancialTypesActionEnum } from '../constants';
import { DropdownType } from '../types';

type FinancialTypesStateType = {
  isLoading: boolean,
  ids: null | { [key: string]: string },
  valueById: null | { [id: string]: DropdownType<string> },
  isFailed: boolean,
};

const initState: FinancialTypesStateType = {
  isLoading: true,
  ids: null,
  valueById: null,
  isFailed: false,
};

type FinancialTypesAction = {
  type: FinancialTypesActionEnum.FINANCIAL_TYPES_LOADING
} | {
  type: FinancialTypesActionEnum.FINANCIAL_TYPES_SUCCESS,
  payload: {
    ids: FinancialTypesStateType['ids'],
    valueById: FinancialTypesStateType['valueById'],
  }
} | {
  type: FinancialTypesActionEnum.FINANCIAL_TYPES_FAILED,
};

const financialTypes = (state = initState, action: FinancialTypesAction) => {
  switch (action.type) {
    case FinancialTypesActionEnum.FINANCIAL_TYPES_LOADING:
      return {
        isLoading: true,
        ids: null,
        valueById: null,
        isFailed: false,
      };
    case FinancialTypesActionEnum.FINANCIAL_TYPES_SUCCESS:
      return {
        isLoading: false,
        ids: action.payload.ids,
        valueById: action.payload.valueById,
        isFailed: false,
      };
    case FinancialTypesActionEnum.FINANCIAL_TYPES_FAILED:
      return {
        isLoading: false,
        ids: null,
        valueById: null,
        isFailed: true,
      };
    default:
      return state;
  }
};

export default financialTypes;
