import { CoreActionEnum } from "../constants";

type CoreStateType = {
  alert: {
    isVisible: boolean,
    message: string,
  },
  platformUpdate: {
    isVisible: boolean,
    text: string,
    link: string,
    id: number | null,
  },
  updatingLoader: {
    isUpdating: boolean,
  }
};

const initialState: CoreStateType = {
  alert: { isVisible: false, message: '' },
  platformUpdate: {
    isVisible: false, text: '', link: '', id: null,
  },
  updatingLoader: { isUpdating: false },
};

type CoreAction = {
  type: CoreActionEnum.SHOW_HIDE_ALERT,
  payload: CoreStateType['alert'],
} | {
  type: CoreActionEnum.SHOW_HIDE_PLATFORM_UPDATE,
  payload: boolean,
} | {
  type: CoreActionEnum.SET_PLATFORM_UPDATE_TEXT,
  payload: string,
} | {
  type: CoreActionEnum.SET_PLATFORM_UPDATE_LINK,
  payload: string,
} | {
  type: CoreActionEnum.SET_PLATFORM_UPDATE_ID,
  payload: number | null,
} | {
  type: CoreActionEnum.SET_ISUPDATING_MODE,
} | {
  type: CoreActionEnum.CANCEL_UPDATING_MODE,
};

export default function core(state: CoreStateType = initialState, action: CoreAction) {
  switch (action.type) {
    case CoreActionEnum.SHOW_HIDE_ALERT:
      return { ...state, alert: action.payload };
    case CoreActionEnum.SHOW_HIDE_PLATFORM_UPDATE:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          isVisible: action.payload,
        },
      };
    case CoreActionEnum.SET_PLATFORM_UPDATE_TEXT:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          text: action.payload,
        },
      };
    case CoreActionEnum.SET_PLATFORM_UPDATE_LINK:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          link: action.payload,
        },
      };
    case CoreActionEnum.SET_PLATFORM_UPDATE_ID:
      return {
        ...state,
        platformUpdate: {
          ...state.platformUpdate,
          id: action.payload,
        },
      };
    case CoreActionEnum.SET_ISUPDATING_MODE:
      return {
        ...state,
        updatingLoader: { isUpdating: true },
      };
    case CoreActionEnum.CANCEL_UPDATING_MODE:
      return {
        ...state,
        updatingLoader: { isUpdating: false },
      };
    default:
      return state;
  }
}
