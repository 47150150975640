import * as types from '../constants';
import { getProjectUnreadMessagesCount, PROJECT_STATUS } from '../utils';

const initialState = {
  project: {},
  projectFinancial: {},
  isAccepted: false,
  isFeedbackReady: false,
  isFirstDraftUploaded: false,
  isFirstDraftDeleted: false,
  activeFeedbackCard: false,
  isCompleteProjectVisible: false,
  messages: [],
  draftMessage: null,
  unreadMessages: 0,
  evaluations: null,
  isEvaluationsApproved: null,
  isTranslatedFilesDownloaded: false,
};

export default function projectDetails(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROJECT_BY_ID_SUCCESS:
    case types.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload,
        isTranslatedFilesDownloaded: !!action.payload.is_translated_files_downloaded ?? false,
        unreadMessages: getProjectUnreadMessagesCount({
          project: action.payload,
          userID: action.userID,
        }),
      };
    case types.DOWNLOAD_TRANSLATED_FILES:
      return {
        ...state,
        isTranslatedFilesDownloaded: action.payload,
      };
    case types.DELETE_DRAFTS_SUCCESS:
      return {
        ...state,
        project: {},
      };
    case types.ACCEPT_PROJECT_SUCCESS:
      return {
        ...state,
        isAccepted: true,
        isFeedbackReady: true,
        project: {
          ...state.project,
          state: action.payload,
          state_num: action.stateNum,
        },
      };
    case types.UNACCEPT_PROJECT:
      return {
        ...state,
        isAccepted: false,
      };
    case types.TRANSFER_TO_FREELANCER_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          writer: action.payload,
        },
      };
    case types.TRANSFER_TO_DESIGNER_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          designer: action.payload,
        },
      };
    case types.DRAFT_READY_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          state: action.payload,
        },
        isFeedbackReady: false,
      };
    case types.FINISH_EVALUATION:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload,
          external_freelancer: state.project.external_freelancer,
          writer: {
            ...action.payload.writer,
            user: state.project.writer?.user,
          },
        },
      };
    case types.FEEDBACK_DELIVERED_SUCCESS:
      return {
        ...state,
        project: {
          ...state.project,
          state: action.payload,
        },
      };
    case types.GET_FILES_SUCCESS: {
      return {
        ...state,
        project: {
          ...state.project,
          files: action.payload,
        },
      };
    }
    case types.UPLOAD_DRAFT_SUCCESS:
      return {
        ...state,
        isFirstDraftUploaded: true,
        isFirstDraftDeleted: false,
        project: {
          ...state.project,
          submitdrafts: [...state.project.submitdrafts, action.payload],
        },
      };
    case types.DELETE_DRAFT_SUCCESS: {
      return {
        ...state,
        isFirstDraftDeleted: true,
        isFirstDraftUploaded: false,
        project: {
          ...state.project,
          submitdrafts: [
            ...state.project.submitdrafts.filter(item => item.id !== action.payload),
          ],
        },
      };
    }
    case types.DELIVER_FEEDBACK:
      return {
        ...state,
        activeFeedbackCard: true,
      };
    case types.DISABLE_ACTIVE_FEEDBACKCARD: {
      return {
        ...state,
        activeFeedbackCard: false,
      };
    }
    case types.TOGGLE_COMPLETE_PROJECT_MODAL:
      return {
        ...state,
        isCompleteProjectVisible: !state.isCompleteProjectVisible,
      };
    case types.SHOW_COMPLETE_PROJECT_MODAL:
      return {
        ...state,
        isCompleteProjectVisible: true,
      };
    case types.HIDE_COMPLETE_PROJECT_MODAL:
      return {
        ...state,
        isCompleteProjectVisible: false,
      };
    case types.UPLOAD_FEEDBACK_SUCCESS: {
      const draft = state.project.submitdrafts.find(item => item.id === action.payload.draftId);
      const files = [
        ...draft.files,
        action.payload.feedback,
      ];
      return {
        ...state,
        project: {
          ...state.project,
          submitdrafts: [
            ...state.project.submitdrafts.filter(item => item.id !== action.payload.draftId),
            {
              ...draft,
              files,
            },
          ],
          state: action.payload.state,
        },
      };
    }
    case types.DELETE_FEEDBACK_SUCCESS: {
      const draft = state.project.submitdrafts.find(({ id }) => id === action.payload.draftId);
      const files = draft.files.filter(({ id }) => id !== action.payload.fileId);

      return {
        ...state,
        project: {
          ...state.project,
          submitdrafts: [
            ...state.project.submitdrafts.filter(item => item.id !== action.payload.draftId),
            {
              ...draft,
              files,
            },
          ],
          state: PROJECT_STATUS.WAITING_FOR_FEEDBACK,
        },
      };
    }
    case types.FINISH_PROJECT_SUCCESS:
      return {
        ...state,
        isFirstDraftUploaded: false,
        isFirstDraftDeleted: false,
      };
    case types.GET_LIST_OF_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload,
      };
    case types.SET_DRAFT_MESSAGE:
      return {
        ...state,
        draftMessage: action.payload,
      };
    case types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case types.MARK_AS_READ:
      return {
        ...state,
        unreadMessages: 0,
      };
    case types.MARK_MESSAGE_AS_DELETED:
      return {
        ...state,
        messages: state.messages.map((message) => (
          message.id === action.payload.id
            ? { ...message, deleted: action.payload.deleted }
            : message
        )),
      };
    case types.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [...action.payload],
      };
    case types.GET_PROJECT_FINANCIAL_SUCCESS:
      return {
        ...state,
        projectFinancial: action.payload,
      };
    // TODO this is useless action for now
    case types.UPDATE_PROJECT_FINANCIAL_SUCCESS:
      return {
        ...state,
        projectFinancial: {
          ...state.projectFinancial,
          financial: action.payload.financial,
          project_priority_fee: action.payload.project_priority_fee,
        },
        project: {
          ...state.project,
          financial: action.payload.financial,
        },
      };
    case types.FINISH_SUCCESS:
      return {
        ...state,
      };
    case types.CLEAR_PROJECT_DATA:
      return initialState;
    case types.GET_FREELANCER_EVALUATIONS:
      return {
        ...state,
        evaluations: action.payload,
        isEvaluationsApproved: action.isEvaluationsApproved,
        // Update project status if all evaluations are approved
        project: {
          ...state.project,
          state: action.isEvaluationsApproved
            ? PROJECT_STATUS.COMPLETED
            : state.project.state,
        },
      };
    case types.UPDATE_FREELANCER_EVALUATIONS:
      return {
        ...state,
        evaluations: [
          ...state.evaluations.map(evaluation => (evaluation.id === action.id ? action.payload : evaluation)),
        ],
      };
    case types.START_NEW_EVALUATION:
      return {
        ...state,
        evaluations: [
          ...state.evaluations,
          action.payload,
        ],
      };
    case types.UPDATE_PROJECT_DETAILS_PARTIALLY:
      return {
        ...state,
        project: {
          ...state.project,
          ...action.payload,
        },
      };
    case types.SET_QUANTITY_REQUEST:
      return {
        ...state,
        project: {
          ...state.project,
          quantity_request: action.payload,
        },
        messages: (state.messages || []).map((message) => (
          message.quantity_request?.id === action.payload.id
            ? ({ ...message, quantity_request: action.payload })
            : message
        )),
      };
    default:
      return state;
  }
}
