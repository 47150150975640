import { NotesActionEnum } from '../constants';
import { SimpleUserType } from '../utils';
import { ListItemWithTitle } from '../types';

type NoteType = {
  id: number,
  note_type_id: number,
  noted_id: number,
  noted?: {
    id: number,
    deactivation?: {
      created_at: string,
    }
  },
  updated: string,
  author?: SimpleUserType,
  is_pinned: boolean,
  note: string,
  file: ListItemWithTitle | null,
  file_id: number | null,
};

type NotesStateType = NoteType[];

const initialState: NotesStateType = [];

type NotesAction = {
  type: NotesActionEnum.GET_NOTES,
  payload: NoteType[],
};

export default function notes(state: NotesStateType = initialState, action: NotesAction) {
  switch (action.type) {
    case NotesActionEnum.GET_NOTES:
      return action.payload;
    default:
      return state;
  }
}
