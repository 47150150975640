import { DEFAULT_PAGINATION_LIMITS } from '../../components/dashboard/components/ProjectsTab/constants';
import { PROJECT_SORTING_IDS } from '../constants';

const getPaginationValue = ({
  key,
  role,
}) => {
  const valueFromStore = Number(localStorage.getItem(key));
  const defaultLimit = DEFAULT_PAGINATION_LIMITS[role] || DEFAULT_PAGINATION_LIMITS.DEFAULT;
  return valueFromStore || defaultLimit;
};

const setLocalStorageValue = ({ key, value }) => {
  localStorage.setItem(key, value);
};

const getAllowedOrders = ({
  t, isConceptBriefings = false, isFinished = false, isFreelancer, isAdmin, isClient,
}) => {
  const actionBased = isAdmin
    ? 'admin'
    : isClient ? 'client' : 'freelancer';

  const isMine = isFreelancer || isClient;

  const newestOldestType = isFinished
    // maybe we need to set other key for freelancer
    ? 'finished_at'
    : 'created_at';

  const allowOrders = [
    {
      id: PROJECT_SORTING_IDS.NEWEST_ON_TOP,
      name: t('newest_on_top'),
      type: newestOldestType,
      order: 'desc',
      additionalParams: {
        isMine,
      },
      dataTestId: 'newest_on_top'
    },
    {
      id: PROJECT_SORTING_IDS.OLDEST_ON_TOP,
      name: t('oldest_on_top'),
      type: newestOldestType,
      order: 'asc',
      additionalParams: {
        isMine,
      },
      dataTestId: 'oldest_on_top'
    },
    {
      id: PROJECT_SORTING_IDS.UPCOMING_DEADLINE_FIRST,
      name: t('deadline_first'),
      type: 'first_draft_deadline_at',
      order: 'asc',
      additionalParams: {
        isMine,
      },
      hide: isConceptBriefings,
    },
    {
      id: PROJECT_SORTING_IDS.FURTHEST_DEADLINE_FIRST,
      name: t('furthest_deadline_first'),
      type: 'first_draft_deadline_at',
      order: 'desc',
      additionalParams: {
        isMine,
      },
      hide: true,
    },
    {
      id: PROJECT_SORTING_IDS.DRAFT_FIRST,
      name: t('draft_first'),
      // type: 'draft_first',
      // order: 'desc',
      additionalParams: {
        draftsFirst: true,
        isMine,
      },
      hide: true, // as we have separate tab for this (concept briefings)
    },
    {
      id: PROJECT_SORTING_IDS.ACTION_BASED_FIRST,
      name: t('action_based_first'),
      // type: 'action_based_first',
      // order: 'desc',
      additionalParams: {
        actionBased,
        isMine,
      },
      hide: isConceptBriefings,
    },
  ];

  return allowOrders;
};

const getSortingIdValue = ({
  key,
}) => {
  const sortingIdFromStore = localStorage.getItem(key);
  const defaultSortingId = PROJECT_SORTING_IDS.DEFAULT;
  return sortingIdFromStore || defaultSortingId;
};

export {
  getPaginationValue,
  setLocalStorageValue,
  getAllowedOrders,
  getSortingIdValue,
};
