import * as types from '../constants';
import getApiData from './api-data';
import sentryLogger from '../services/sentry';
import {
  ACTIVE_PROJECT_PAGINATION_KEY,
  ACTIVE_PROJECT_SORTING_KEY,
  clearUserAuthData,
  client,
  CONCEPT_BRIEFINGS_PAGINATION_KEY,
  FINISHED_PROJECT_PAGINATION_KEY,
  FINISHED_PROJECT_SORTING_KEY,
  INACTIVE_USER_STATES,
  isClientUserType,
  logoutCrowdin,
  quest,
  setUserAuthData,
} from '../utils';

import { redirectToDeactivatedProfilePage } from '../utils/helpers/redirectHelpers';
import { getProjectTypes } from './api-data/project-types';
import { getPermissions } from './permissionsList';
import {
  setPlatformUpdateID, setPlatformUpdateLink, setPlatformUpdateText, showHidePlatformUpdate,
} from './core';

const shouldRedirect = (array = []) => (
  array.some(item => item.test(window.location.pathname))
);

export const setImpersonate = (who) => ({
  type: types.IMPERSONATE,
  payload: who,
});

export const setImpersonatedData = (userData) => ({
  type: types.SET_IMPERSONATED_DATA,
  payload: userData,
});

export const getImpersonateData = (id, isCompany = false) => (
  (dispatch) => {
    const url = `/impersonate/${id}`;

    return client.post(url)
      .then(({ success, result }) => {
        if (!success) {
          throw new Error('Impersonate Error');
        }

        const impersonatedData = {
          id: result.id,
          name: isCompany
            ? result.client?.company ?? ''
            : result.name,
          avatar: isCompany
            ? result.client?.avatar ?? ''
            : result.avatar,
          type: result.type,
          roles: result.roles,
          client_id: result.client_id || null,
        };
        dispatch(setImpersonatedData(impersonatedData));

        return result;
      })
      .catch(error => {
        throw error.data;
      });
  });

const restoreImpersonateIfExist = () => (
  async (dispatch) => {
    const impersonatedId = localStorage.getItem('impersonate_id');
    const isImpersonated = !!impersonatedId;
    const impersonatedType = localStorage.getItem('type');
    const isCompany = localStorage.getItem('impersonate_is_company') === 'true';

    const shouldRestoreImpersonate = isImpersonated && !!impersonatedType;
    if (shouldRestoreImpersonate) {
      return dispatch(getImpersonateData(impersonatedId, isCompany));
    }
  }
);

export const receiveAuth = () => (
  async (dispatch, getState) => {
    const { user } = getState().auth;

    // we use token from client instance
    // check if token missing. If true - return
    if (!client.defaults.headers.common.Authorization) {
      throw new Error('Auth header missing');
    }

    const url = '/getProfile';

    return client
      .get(url)
      .then(async ({ data: { result } }) => {
        dispatch({
          type: types.RECEIVE_PROFILE_SUCCESS,
          payload: result,
        });

        try {
          // we need to wait until impersonation finished before auth action will be done
          await dispatch(restoreImpersonateIfExist());
        } catch {
          // impersonation failed
        }

        try {
          await dispatch(getPermissions());
        } catch {
          // fetching permissions failed
        }

        return result;
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        throw new Error(error);
      });
  }
);

export const MISSED_REFRESH_TOKEN_ERROR = 'Refresh token is missed';
export const refreshAccessToken = () => {
  const refreshToken = localStorage.getItem('refresh_token');

  if (!refreshToken) throw new Error(MISSED_REFRESH_TOKEN_ERROR);

  return client.post('/refreshToken', { refresh_token: refreshToken })
    .then(({ data: { result } }) => result);
};

export function login(email, password, lang, remember = false) {
  return dispatch => {
    const url = '/login';

    return quest.post(url, { email, password, remember })
      .then(({ data: { result } }) => {
        if (!result.access_token) {
          throw new Error('Token has not been provided');
        }

        setUserAuthData(result);

        return dispatch(receiveAuth())
          .then(() => {
            dispatch(getApiData());
          });
      })
      .catch((error) => {
        const { data: { data: { user } } } = error;
        // TODO there must be a condition
        sentryLogger.exceptionWithScope(error, { url });

        dispatch({
          type: types.LOGIN_FAILURE,
          payload: 'Invalid email or password',
        });

        clearUserAuthData();

        const userState = user?.state;
        const isInactiveState = INACTIVE_USER_STATES.includes(userState);

        const isClient = isClientUserType(user?.type);
        const isClientInactiveState = INACTIVE_USER_STATES.includes(user?.client?.state);
        const isClientInactive = isClient && isClientInactiveState;

        const shouldShowDeactivatedPage = isInactiveState || isClientInactive;
        shouldShowDeactivatedPage && redirectToDeactivatedProfilePage(lang, user);
      });
  };
}

export function logout() {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/logout';

    client.post(url)
      .then(() => {
        dispatch({
          type: types.LOGOUT_SUCCESS,
        });

        clearUserAuthData(true);

        logoutCrowdin();
      })
      .catch((error) => {
        sentryLogger.exceptionWithScope(error, { user, url });
      });
  };
}

/**
 * @param {string|number} id
 * @param {History | any} history
 * @param {string?} who
 * @param {string?} name
 * @param {number|null} [clientId=null]
 * @param {boolean} isCompany
 */
export function impersonate(id, history, who, name, clientId = null, isCompany = false) {
  return (dispatch) => {
    dispatch(getImpersonateData(id, isCompany))
      .then(() => {
        logoutCrowdin();
        dispatch(setImpersonate(who));

        localStorage.setItem('impersonate_id', id);
        localStorage.setItem('impersonate_is_company', isCompany);
        localStorage.setItem('impersonate_name', name);
        localStorage.setItem('type', who);

        // clear dashboard sorting and view params
        localStorage.removeItem(CONCEPT_BRIEFINGS_PAGINATION_KEY);
        localStorage.removeItem(ACTIVE_PROJECT_PAGINATION_KEY);
        localStorage.removeItem(FINISHED_PROJECT_PAGINATION_KEY);
        localStorage.removeItem(ACTIVE_PROJECT_SORTING_KEY);
        localStorage.removeItem(FINISHED_PROJECT_SORTING_KEY);

        if (clientId) {
          localStorage.setItem('client_id', clientId);
        }

        history.push('/dashboard/projects');
      });
  };
}

export function unimpersonate(who, history) {
  return async (dispatch) => {
    const url = `/unimpersonate`;

    const data = await client.post(url);
    if (!data) {
      throw new Error('UnImpersonate Error');
    }
    logoutCrowdin();

    dispatch(getProjectTypes());

    dispatch({
      type: types.UNIMPERSONATE,
    });
    localStorage.removeItem('impersonate_id');
    localStorage.removeItem('impersonate_is_company');
    localStorage.removeItem('impersonate_name');
    localStorage.removeItem('type');
    localStorage.removeItem('client_id');
    localStorage.removeItem('show_freelancer_warning');
    localStorage.removeItem('uncomplete_umount');

    // clear dashboard sorting and view params
    localStorage.removeItem(ACTIVE_PROJECT_PAGINATION_KEY);
    localStorage.removeItem(FINISHED_PROJECT_PAGINATION_KEY);
    localStorage.removeItem(ACTIVE_PROJECT_SORTING_KEY);
    localStorage.removeItem(FINISHED_PROJECT_SORTING_KEY);

    const regArray = [
      /\bclient-settings\b/,
      /\badd-writer-to-team\b/,
      /\badd-designer-to-team\b/,
      /\bcopywriter-settings\b/,
      /\bdesigner-settings\b/,
    ];

    if (shouldRedirect(regArray)) {
      if (isClientUserType(who)) {
        history.push('/dashboard/clients');
      } else {
        history.push('/dashboard/freelancers/list/copy');
      }
    } else {
      // call redirect async to wait until store will be updated
      setTimeout(() => window.location.reload(), 0);
    }
  };
}

export function resetPassword(mail, history) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = `/sendResetLinkEmail`;

    return quest.post(url, { email: mail })
      .then(data => {
        if (!data) {
          throw new Error('The selected email is invalid.');
        }
        if (data.data.success) {
          dispatch({
            type: types.RESET_PASSWORD_SUCCESS,
          });
          history.push('/en/reset-password-confirmation');
        }
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });

        dispatch({
          type: types.RESET_PASSWORD_FAILURE,
          payload: error,
        });
      });
  };
}

export function newPassword(data, history) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/resetPassword';

    return quest.post(url, data)
      .then(response => {
        if (!response.data.success) {
          throw new Error(response.data.result);
        }
        dispatch({
          type: types.REQUEST_NEW_PASSWORD_SUCCESS,
        });
        history.push('/login');
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });

        dispatch({
          type: types.REQUEST_NEW_PASSWORD_FAILURE,
          payload: error,
        });
      });
  };
}

export function hideUpdateBar() {
  return (dispatch) => {
    dispatch(showHidePlatformUpdate(false));

    dispatch(setPlatformUpdateText(''));

    sessionStorage.setItem('hideUpdateBar', 'true');
  };
}

export function updateAlertInfo(id, active, text, readMoreLink) {
  return (dispatch, getState) => {
    if (!id) throw new Error('The id is invalid.');

    const { user } = getState().auth;
    const url = `/alerts/${id}`;
    const data = {
      type: 'alert-info',
      active: active ? '1' : '0',
      text,
      read_more: readMoreLink,
    };

    if (!active) {
      localStorage.removeItem('info_alert_id');
      localStorage.removeItem('info_alert_text');
      localStorage.removeItem('info_alert_link');
      localStorage.removeItem('info_alert_active');
    }

    return client.put(url, data)
      .then(response => {
        if (response.data.success) {
          const infoAlert = response.data.result;
          if (infoAlert) {
            dispatch(setPlatformUpdateID(infoAlert.id));

            const newIsActive = +infoAlert.active === 1;

            localStorage.setItem('info_alert_text', infoAlert.text);
            localStorage.setItem('info_alert_link', infoAlert.read_more);
            newIsActive && localStorage.setItem('info_alert_active', infoAlert.active);

            dispatch(showHidePlatformUpdate(newIsActive));

            dispatch(setPlatformUpdateText(infoAlert.text));

            dispatch(setPlatformUpdateLink(infoAlert.read_more));
          }
        }
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        return Promise.reject(error);
      });
  };
}

export function verifyEmail(token) {
  return () => {
    const url = `/user/verify-email/${token}`;

    return quest.get(url)
      .then(data => data)
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { url });
        throw error.data;
      });
  };
}

export function sendResetPassword(email) {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/sendResetLinkEmail';

    const requestData = {
      email,
    };

    return client.post(url, requestData)
      .then(() => {
        dispatch({
          type: types.SEND_RESET_PASSWORD_LINK,
        });
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
        throw error.data;
      });
  };
}
