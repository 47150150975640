import { DropdownType, Translation } from '../../types';
import { PRODUCT_COPY_CONTENT_TYPE_ID, PROOFREADING_CONTENT_TYPE_ID, SHORT_COPY_CONTENT_TYPE_ID } from '../helpers';

export enum ServiceTypeId {
  CONTENT_CREATION = 1,
  REWRITING = 4,
  EDITING = 2,
  LOCALISATION = 3,
  AUDIO_TRANSCRIPTION = 6,
  DESIGN = 5,
  // ADDITIONAL_SERVICES = 7,
  // TODO
  PROFESSIONAL_SERVICES = 8,
  // new service
  TRANSLATION = 9,
  EVALUATION = 10,
  PROFESSIONAL_SERVICE_VIDEO = 37,
  PROFESSIONAL_SERVICE_PODCAST = 38,
}

export const ADDITIONAL_SERVICES_OLD_ID = 7;

export const translationServices = [
  ServiceTypeId.LOCALISATION,
  ServiceTypeId.TRANSLATION,
];
export const servicesWithContentTypes = [
  ServiceTypeId.CONTENT_CREATION,
  ServiceTypeId.EDITING,
  ServiceTypeId.LOCALISATION,
  ServiceTypeId.REWRITING,
  ServiceTypeId.TRANSLATION,
];

// For now we cannot select TRANSLATION service
// only in localisation case on separate step we can select it fpr each language pair separately
export type SelectableServiceTypeId = Exclude<ServiceTypeId, ServiceTypeId.TRANSLATION | ServiceTypeId.EVALUATION>;

export const getCopyServices = (t: Translation): DropdownType[] => [
  { id: 1, name: t('content_creation') },
  { id: 2, name: t('editing') },
  { id: 3, name: t('localisation') },
  { id: 4, name: t('re_writing') },
  { id: 6, name: t('audio_transcription') },
  { id: ServiceTypeId.TRANSLATION, name: t('translation') },
];

export const isHourContentType = ({ serviceID, contentTypeID }: { serviceID: ServiceTypeId, contentTypeID: number }): boolean => (
  (serviceID === ServiceTypeId.CONTENT_CREATION && [
    PRODUCT_COPY_CONTENT_TYPE_ID,
    SHORT_COPY_CONTENT_TYPE_ID,
  ].includes(contentTypeID))
  || (serviceID === ServiceTypeId.EDITING && [
    PROOFREADING_CONTENT_TYPE_ID,
  ].includes(contentTypeID))
);
