import { client } from '../utils';
import sentryLogger from '../services/sentry';
import {
  setPlatformUpdateID, setPlatformUpdateLink, setPlatformUpdateText, showHidePlatformUpdate,
} from './core';

export function showUpdateBar() {
  return (dispatch) => {
    const infoAlertText = localStorage.getItem('info_alert_text');
    const infoAlertLink = localStorage.getItem('info_alert_link');
    const showAlert = !sessionStorage.getItem('hideUpdateBar') && Boolean(localStorage.getItem('info_alert_active'));

    dispatch(showHidePlatformUpdate(showAlert));

    dispatch(setPlatformUpdateText(infoAlertText));

    dispatch(setPlatformUpdateLink(infoAlertLink));
  };
}

export function getAlertInfo() {
  return (dispatch, getState) => {
    const { user } = getState().auth;
    const url = '/alerts';

    return client.get(url)
      .then(data => {
        if (data.data.success) {
          const infoAlert = data.data.result.find(item => item.type === 'alert-info');
          if (infoAlert) {
            dispatch(setPlatformUpdateID(infoAlert.id));

            localStorage.setItem('info_alert_text', infoAlert.text);
            localStorage.setItem('info_alert_link', infoAlert.read_more);
            infoAlert.active === '1' && localStorage.setItem('info_alert_active', infoAlert.active);
            const sessionHideBar = Boolean(sessionStorage.getItem('hideUpdateBar'));

            dispatch(showHidePlatformUpdate(infoAlert.active && !sessionHideBar));

            dispatch(setPlatformUpdateText(infoAlert.text));

            dispatch(setPlatformUpdateLink(infoAlert.read_more));
          }
        }
      })
      .catch(error => {
        sentryLogger.exceptionWithScope(error, { user, url });
      });
  };
}
